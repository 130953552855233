import { ThemeOptions } from '@material-ui/core'
import merge from 'lodash/merge'

const makeDense = (themeOptions: ThemeOptions): ThemeOptions => {
  const dense: ThemeOptions = {
    overrides: {
      ...themeOptions.overrides,
      MuiFormControl: {
        ...themeOptions.overrides?.MuiFormControl,
        marginDense: {
          ...themeOptions.overrides?.MuiFormControl?.marginDense,
          marginBottom: 0,
          marginTop: 0,
        },
      },
      MuiIconButton: {
        ...themeOptions.overrides?.MuiIconButton,
        sizeSmall: {
          ...themeOptions.overrides?.MuiIconButton?.sizeSmall,
          // Adjust spacing to reach minimal touch target hitbox
          marginLeft: 4,
          marginRight: 4,
          padding: 12,
        },
      },
    },
    props: {
      MuiButton: {
        ...themeOptions.props?.MuiButton,
        size: 'small',
      },
      MuiFab: {
        ...themeOptions.props?.MuiFab,
        size: 'small',
      },
      MuiFilledInput: {
        ...themeOptions.props?.MuiFilledInput,
        margin: 'dense',
      },
      MuiFormControl: {
        ...themeOptions.props?.MuiFormControl,
        margin: 'dense',
      },
      MuiFormHelperText: {
        ...themeOptions.props?.MuiFormHelperText,
        margin: 'dense',
      },
      MuiIconButton: {
        ...themeOptions.props?.MuiIconButton,
        size: 'small',
      },
      MuiInputBase: {
        ...themeOptions.props?.MuiInputBase,
        margin: 'dense',
      },
      MuiInputLabel: {
        ...themeOptions.props?.MuiInputLabel,
        margin: 'dense',
      },
      MuiListItem: {
        ...themeOptions.props?.MuiListItem,
        dense: true,
      },
      MuiOutlinedInput: {
        ...themeOptions.props?.MuiOutlinedInput,
        margin: 'dense',
      },
      MuiTable: {
        ...themeOptions.props?.MuiTable,
        size: 'small',
      },
      MuiTextField: {
        ...themeOptions.props?.MuiTextField,
        margin: 'dense',
      },
      MuiToolbar: {
        ...themeOptions.props?.MuiToolbar,
        variant: 'dense',
      },
    },
  }
  return merge({}, themeOptions, dense)
}

export default makeDense
