import React, { PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet'
import { FlexGrowCol } from 'sdk-xy-react'

interface BasePageProps {
  noindex?: boolean
  title?: string
}

const BasePage: React.FC<PropsWithChildren<BasePageProps>> = (props) => {
  const { children, noindex = false, title = 'Your Data, Your Rules' } = props

  return (
    <FlexGrowCol justifyContent="flex-start">
      <Helmet title={`Lifehash: ${title}`}>{noindex ? <meta content="noindex" name="robots" /> : null}</Helmet>
      {children}
    </FlexGrowCol>
  )
}

export default BasePage
