import { BoxProps, createMuiTheme, responsiveFontSizes, ThemeOptions, ThemeProvider } from '@material-ui/core'
import React, { useContext } from 'react'

import makeDense from './makeDense'

interface InvertableTheme {
  options: ThemeOptions
}

interface InvertableThemeProviderProps {
  options?: ThemeOptions
  dense?: boolean
  tight?: boolean
  dark?: boolean
  responsiveFonts?: boolean
  invert?: boolean
  boxProps?: BoxProps
}

export const InvertableThemeContext = React.createContext<InvertableTheme>({
  options: {},
})

function clone<T>(a: T): T {
  return JSON.parse(JSON.stringify(a))
}

const InvertableThemeProvider: React.FC<InvertableThemeProviderProps> = (props) => {
  const contextInvertableTheme = useContext(InvertableThemeContext)
  const {
    boxProps,
    children,
    dark = false,
    invert = false,
    tight = false,
    dense = true,
    responsiveFonts = true,
  } = props
  let { options = clone(contextInvertableTheme.options) } = props

  options.palette = options.palette ?? {}

  if (invert) {
    options.palette = options.palette ?? {}
    options.palette.type = options.palette.type === 'dark' ? 'light' : 'dark'
  }

  if (dark !== undefined) {
    options.palette = options.palette ?? {}
    options.palette.type = dark ? 'dark' : 'light'
  }

  if (dense) {
    options = makeDense(options)
  }

  if (tight) {
    options.spacing = 4
  }

  let theme = createMuiTheme(options)

  if (responsiveFonts) {
    theme = responsiveFontSizes(theme)
  }

  return (
    <InvertableThemeContext.Provider value={{ options }} {...boxProps}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </InvertableThemeContext.Provider>
  )
}

export default InvertableThemeProvider
