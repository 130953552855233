import { ThemeOptions } from '@material-ui/core'

import fontFamily from './fontFamily'

const themeOptions: ThemeOptions = {
  overrides: {
    MuiButton: {
      root: {
        overflow: 'hidden',
      },
    },
    MuiLink: {
      root: {
        '&:hover': {
          filter: 'brightness(75%)',
        },
      },
    },
    MuiPaper: {
      root: {
        overflow: 'hidden',
      },
    },
  },
  palette: {
    primary: {
      main: '#1565c0',
    },
    secondary: {
      main: '#f9a825',
    },
  },
  props: {
    MuiLink: {
      underline: 'none',
    },
  },
  typography: {
    body1: {
      fontFamily: fontFamily.primary,
      marginBottom: '0.5rem',
      marginTop: '0.5rem',
    },
    button: {
      textTransform: 'none',
    },
    caption: {
      fontFamily: fontFamily.headings,
      fontSize: '1em',
    },
    fontFamily: fontFamily.primary,
    h1: {
      fontFamily: fontFamily.headings,
      fontSize: '3.2em',
      marginBottom: '0.8em',
      marginTop: '1.6em',
    },
    h2: {
      fontFamily: fontFamily.headings,
      fontSize: '2.8em',
      marginBottom: '0.7em',
      marginTop: '1.4em',
    },
    h3: {
      fontFamily: fontFamily.headings,
      fontSize: '2.24em',
      marginBottom: '0.56em',
      marginTop: '1.12em',
    },
    h4: {
      fontFamily: fontFamily.headings,
      fontSize: '2em',
      marginBottom: '0.5em',
      marginTop: '1em',
    },
    h5: {
      fontFamily: fontFamily.headings,
      fontSize: '1.6em',
      marginBottom: '0.4em',
      marginTop: '0.8em',
    },
    h6: {
      fontFamily: fontFamily.headings,
      fontSize: '1.2em',
      marginBottom: '0.3em',
      marginTop: '0.6em',
    },
    subtitle1: {
      fontFamily: fontFamily.headings,
      opacity: '50%',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontSize: '0.85em',
      opacity: '50%',
    },
  },
}

export default themeOptions
