import { Box, Container } from '@material-ui/core'
import React from 'react'
import { FlexCol } from 'sdk-xy-react'

import BasePage from '../../BasePage'
import Page1 from './img/Terms 1.1.20-01.png'
import Page2 from './img/Terms 1.1.20-02.png'
import Page3 from './img/Terms 1.1.20-03.png'
import Page4 from './img/Terms 1.1.20-04.png'
import Page5 from './img/Terms 1.1.20-05.png'
import Page6 from './img/Terms 1.1.20-06.png'
import Page7 from './img/Terms 1.1.20-07.png'
import Page8 from './img/Terms 1.1.20-08.png'
import Page9 from './img/Terms 1.1.20-09.png'
import Page10 from './img/Terms 1.1.20-10.png'
import Page11 from './img/Terms 1.1.20-11.png'
import Page12 from './img/Terms 1.1.20-12.png'
import Page13 from './img/Terms 1.1.20-13.png'
import Page14 from './img/Terms 1.1.20-14.png'
import Page15 from './img/Terms 1.1.20-15.png'
import Page16 from './img/Terms 1.1.20-16.png'

const images: string[] = [
  Page1,
  Page2,
  Page3,
  Page4,
  Page5,
  Page6,
  Page7,
  Page8,
  Page9,
  Page10,
  Page11,
  Page12,
  Page13,
  Page14,
  Page15,
  Page16,
]

const Terms: React.FC = () => (
  <BasePage noindex title="Terms and Conditions">
    <FlexCol justifyContent="flex-start" width="100vw">
      <Container>
        <Box>
          {images.map((item, index) => (
            <img alt={`Lifehash Terms Page ${index + 1}`} key={index} src={item} width="100%" />
          ))}
        </Box>
      </Container>
    </FlexCol>
  </BasePage>
)

export default Terms
