import { AppBar, Box, Button, IconButton, Toolbar } from '@material-ui/core'
import React from 'react'
import { AiOutlineLogin } from 'react-icons/ai'
import { Link as RouterLink } from 'react-router-dom'

import logo from './img/logo.png'

const PortalToolBar: React.FC = () => {
  const appHost = document.location.host === 'beta.lifehash.org' ? 'beta-app.lifehash.org' : 'app.lifehash.org'
  return (
    <Toolbar variant="regular">
      <Box display="flex" flexDirection="row" width="100vw">
        <Button component={RouterLink} to="/">
          <Box
            height={32}
            margin={1}
            style={{ backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
            width={32}
          />
        </Button>
        <Box display="flex" flexGrow={1} />
        <Box alignItems="right" display="flex" flexDirection="row">
          <Box alignItems="center" display="flex" padding={1}>
            <IconButton href={`https://${appHost}/account/signin`} title="Sign In">
              <AiOutlineLogin size={24} color="white" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Toolbar>
  )
}

const PortalAppBar: React.FC = () => {
  return (
    <Box width="100vw">
      <AppBar position="fixed">
        <PortalToolBar />
      </AppBar>
      <PortalToolBar />
    </Box>
  )
}

export default PortalAppBar
