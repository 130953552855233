import { useTheme } from '@material-ui/core'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { FlexGrowCol, FlexGrowRow } from 'sdk-xy-react'

import LifeHashAppBar from './AppBar'
import Footer from './Components/Footer'
import InvertableThemeProvider from './Components/InvertableThemeProvider'
import About from './Pages/About'
import Home from './Pages/Home'
import Privacy from './Pages/Privacy'
import Support from './Pages/Support'
import Terms from './Pages/Terms'
import themeOptions from './theme/themeOptions'

const AppBody: React.FC = () => {
  const theme = useTheme()
  return (
    <Router>
      <FlexGrowCol
        width="100vw"
        minHeight="100vh"
        justifyContent="flex-start"
        alignContent="stretch"
        bgcolor={theme.palette.background.default}
        color={theme.palette.text.primary}
      >
        <LifeHashAppBar />
        <FlexGrowRow>
          <Switch>
            <Route component={Home} exact path="/" />
            <Route component={Support} exact path="/support" />
            <Route component={About} exact path="/about" />
            <Route component={Terms} exact path="/terms" />
            <Route component={Privacy} exact path="/privacy" />
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </FlexGrowRow>
        <Footer />
      </FlexGrowCol>
    </Router>
  )
}

const AppThemeBody: React.FC = () => {
  return (
    <InvertableThemeProvider options={themeOptions}>
      <AppBody />
    </InvertableThemeProvider>
  )
}

const App: React.FC = () => {
  return (
    <InvertableThemeProvider options={themeOptions}>
      <Helmet defaultTitle="Lifehash.org" titleTemplate="%s | Lifehash.org" />
      <AppThemeBody />
    </InvertableThemeProvider>
  )
}

export default App
